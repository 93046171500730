const SaveRoom = ({ roomId, walls, matrix, boundaries, features, edges, addToLocal, projectName, graphSetId }) => {
     const handleSave = () => {
        if(roomId === -1) {
            return;
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "room_id":roomId,
                                   "edges":edges,
                                   "nodes": features,
                                   "adjacency_matrix":matrix,
                                   "boundaries": boundaries,
                                   "walls": walls,
                               })
            };

         fetch(`/api/projects/${projectName}/graphsets/${graphSetId}/rooms/${roomId}`, requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(`Saved room ${roomId}`);
                console.log(data)
                addToLocal(roomId);
                });
    }
    if(roomId === -1) {
            return;
        }
    return (
           <div>
             <button
                onClick={handleSave}
             >Save&nbsp;Graph</button>
          </div>
    );
};

export default SaveRoom;