import { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

import Edge from './Edge';
import Node from './Node';

const RoomSVG = ({  walls, roomId, scales, features, editEdges, manageEditEdges,
                   featureMap, addEdge, edges, fallbackColor }) => {
    const [srcNode, setSrcNode] = useState();
    const [destNode, setDestNode] = useState();
    if (roomId == -1) { return (<div className="content" id="room-svg" />) }
    let foundFeatures = {}
    const connectNodes = (e) => {
        console.log(e.target.cx)
        if (! srcNode ) {
            setSrcNode(e.target);
            return;
        }
        if (srcNode) {
            console.log("Got src")
            setDestNode(e.target)

            var edge = { "x1": srcNode.cx.baseVal.value,
                         "x2":e.target.cx.baseVal.value,
                         "y1":srcNode.cy.baseVal.value,
                         "y2":e.target.cy.baseVal.value,
                         "src":srcNode.id,
                         "dest":e.target.id,
                         "uuid":uuidv4()
            }
            addEdge(edge)

            setDestNode(null);
            setSrcNode(null);
        }
    }

    return (
        <div className="content" id="room-svg" >
            <svg height="800" width="800">
                {walls.map((wall) => (
                    <line className="wall"
                        x1={scales['x'](wall['x1'])}
                        x2={scales['x'](wall['x2'])}
                        y1={scales['y'](wall['y1'])}
                        y2={scales['y'](wall['y2'])}
                        key={wall['uuid']}
                        />
                ))}
                {features.map( (node) => (
                    <Node
                        node={node}
                        scales={scales}
                        featureMap={featureMap}
                        fallbackColor={fallbackColor}
                        connectNodes={connectNodes}
                        key={node.uuid}
                        srcNode={srcNode}
                    />
                ))}
                {edges.map( (edge) => (
                      <Edge
                        edge={edge}
                        editEdges={editEdges}
                        manageEditEdges={manageEditEdges}
                        key={edge.uuid}
                    />
                ))}
            </svg>
        </div>
    );
};

export default RoomSVG;