import { useState, useEffect } from 'react';

import RoomPicker from '../components/RoomPicker';
import RoomMatrix from '../components/RoomMatrix';
import RoomPickerLocal from '../components/RoomPickerLocal';
import RoomSVG from '../components/RoomSVG';
import RoomLegend from '../components/RoomLegend';
import SaveRoom from '../components/SaveRoom';
import EditEdges from '../components/EditEdges';
import { Link, useLocation } from "react-router-dom";

import * as d3 from 'd3';
import '../App.css';
const featureColorMap = {
    'Wall': 'black',
    'TV': 'blue',
    'Chair': 'coral',
    'Ottoman': 'goldenrod',
    'Table': 'hotpink',
    'Desk': 'red',
    'Shelves': 'lime',
    'Door': 'fuchsia',
    'Board': "olive",
    'Stool': "gold",
    'Cabinet': "forestgreen",
};
const fallbackColor = 'gray';

function App() {
    const [roomId, setRoomId] = useState("-1");
    const [scales, setScales] = useState();
    const [rooms, setRooms] = useState([]);
    const [localRooms, setLocalRooms] = useState([]);
    const [allWalls, setAllWalls] = useState({});
    const [walls, setWalls] = useState([]);
    const [edges, setEdges] = useState([]);
    const [adjMatrix, setAdjMatrix] = useState([]);
    const [boundaries, setBoundaries] = useState({});
    const [featuresStruct, setFeaturesStruct] = useState({});
    const [editEdges, setEditEdges] = useState({});
    const location = useLocation()
    const [graphSet, setGraphSet] = useState({});

    console.log(`Room ${roomId}`)
    const projectName = location.state.projectName;
    const graphSetId = location.state.graphSet;

    console.log(`P ${projectName} g ${graphSetId}`);

    const manageEditEdges = (e) => {
        var edgeId = e.target.id;

        if (edgeId in editEdges) {
            console.log(`Removing ${edgeId}`)
            let currentEdges = { ...editEdges };
            delete currentEdges[edgeId];
            setEditEdges(currentEdges);
        }
        else {
            console.log(`Adding ${edgeId}`)
            setEditEdges({ ...editEdges, [edgeId]: 1 })
        }
    }
    const deleteEditEdges = (e) => {
        //console.log("Deleting edges");

        let currentEditEdges = { ...editEdges };
        let currentEdges = [...edges];
        console.log(currentEdges);
        for (var edgeId of Object.keys(currentEditEdges)) {
            currentEdges = currentEdges.filter((e) => {
                return e.uuid !== edgeId
            });
        }
        //console.log(currentEdges);

        setAdjMatrix(rebuildAdjacencyMatrix(featuresStruct, currentEdges));

        setEdges(currentEdges);
        setEditEdges({});
    }

    const changeRoom = (e) => {
        let room = e.target.value;
        console.log(`change Room ${room}`);
        setRoomId(room);
        setEdges([]);
        setEditEdges({});
        setFeaturesStruct(rooms[room]["features"]);
        setBoundaries(allWalls[room]['boundaries']);
        setWalls(allWalls[room]['walls']);
        setAdjMatrix(rooms[room]["adj_matrix"]);
        setScales(calcScales(allWalls[room]['boundaries'], room));
    }
    const changeRoomLocal = (e) => {
        let room = e.target.value;
        console.log(`change Room local ${room}`);

        fetch(`/api/projects/${projectName}/graphsets/${graphSetId}/rooms/${room}`)
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setRoomId(room);
                setEdges(data["edges"]);
                setEditEdges({});
                setFeaturesStruct(data["nodes"]);
                setBoundaries(data["boundaries"]);
                setWalls(data["walls"]);
                setAdjMatrix(data["adjacency_matrix"]);
                setScales(calcScales(data["boundaries"], room));
            });
    }

    const addEdge = (edge) => {
        var currentEdges = [...edges];
        currentEdges.push(edge);
        setEdges(currentEdges);
        setAdjMatrix(rebuildAdjacencyMatrix(featuresStruct, currentEdges));
    }

    useEffect(() => {
        fetch(`/api/projects/${projectName}/load`)
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                console.log(data);
                setRooms(data['data']);
                setRoomId(-1);
                setAllWalls(data['walls']);
            });
    }, []);
    useEffect(() => {
        fetch(`/api/projects/${projectName}/graphsets/${graphSetId}/rooms`)
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setLocalRooms(data['rooms']);
            });
    }, []);

    useEffect(() => {
        fetch(`/api/projects/${projectName}/graphsets/${graphSetId}`)
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setGraphSet(data);
                document.title = `Room Graph Editor / Project: ${projectName} / Graphset: ${data.description}`;

            });
    }, []);


    const addToLocal = (roomToAdd) => {
        if (localRooms.includes(roomToAdd)) { return }
        var currentLocal = [...localRooms];
        currentLocal.push(roomToAdd);
        setLocalRooms(currentLocal);
    }

   

    const showRoomId = (roomId) => {
        if (roomId != -1) {
            return (
                <h2>Room Editor - Room {roomId}</h2>

            )
}
        else {
            return (<h2>Room Editor - Pick a room </h2>)
        }

    }

    return (
        <div className="app-wrapper">
            <div className="main-head" id="page-header">
                Project <b>{projectName}</b>&nbsp;&nbsp;
                Graphset <b>{graphSet.description}</b>&nbsp;&nbsp;<Link to="/">Change Graphset</Link>
                
                    {showRoomId(roomId)}
                
            </div>

            <RoomPicker
                roomIds={Object.keys(rooms)}
                chosenRoom={roomId}
                onChange={changeRoom}
            />
            <RoomPickerLocal
                roomIds={localRooms}
                onChange={changeRoomLocal}
                chosenRoom={roomId}
            />
            <RoomLegend
                roomId={roomId}
                features={featuresStruct}
                featureMap={featureColorMap}
                fallbackColor={fallbackColor}
            />
            <div id="room-buttons">
                <SaveRoom
                    walls={walls}
                    features={featuresStruct}
                    roomId={roomId}
                    projectName={projectName}
                    edges={edges}
                    matrix={adjMatrix}
                    boundaries={boundaries}
                    addToLocal={addToLocal}
                    graphSetId={graphSetId}
                />
                <EditEdges
                    onDelete={deleteEditEdges}
                    editEdges={editEdges}

                />
            </div>
            <RoomMatrix
                matrix={adjMatrix}
                roomId={roomId}
                projectName={projectName}
                graphSetId={graphSetId}
            />

            <RoomSVG
                walls={walls}
                roomId={roomId}
                scales={scales}
                editEdges={editEdges}
                manageEditEdges={manageEditEdges}
                featureMap={featureColorMap}
                features={featuresStruct}
                addEdge={addEdge}
                edges={edges}
                fallbackColor={fallbackColor}
            />

        </div>
    );
}

function calcScales(boundaries, room_id) {
    var scales = new Object();
    scales['x'] = d3.scaleLinear()
        .domain([boundaries['min_x'], boundaries['max_x']])
        .range([50, boundaries['canvas_x']])
        ;
    scales['y'] = d3.scaleLinear()
        .domain([boundaries['min_y'], boundaries['max_y']])
        .range([50, boundaries['canvas_y']])
        ;
    return scales;
}

function rebuildAdjacencyMatrix(nodes, edges) {
    const initializeZeroMatrix = (size) => {
        return Array.from({ length: size }).map(() =>
            Array.from({ length: size }).fill(0)
        );
    }

    const getNodeIdx = (nodeId) => {
        let nodeIdx = null;
        for (let i = 0; i < nodes.length; i++) {
            if (nodes[i].uuid === nodeId) {
                nodeIdx = i;
                break;
            }
        }
        if (!nodeIdx) {
            console.log(`Missing node ${nodeId}!`);
        }
        return nodeIdx;
    }

    var matrix = initializeZeroMatrix(nodes.length);

    for (let j = 0; j < edges.length; j++) {
        let srcIdx = getNodeIdx(edges[j].src);
        let destIdx = getNodeIdx(edges[j].dest);

        matrix[srcIdx][destIdx] = 1;
        matrix[destIdx][srcIdx] = 1;
    }
    return matrix;
}

export { App as default, featureColorMap };