const EditEdges = ({ editEdges, onDelete }) => {
   var disabledProp={};
   if(! Object.keys(editEdges).length) {
       disabledProp.disabled = true;
   }
   return (
       <div>
            <button
              {...disabledProp}
                    onClick={onDelete}
            >Delete&nbsp;edges</button>
       </div>
   )
};

export default EditEdges;