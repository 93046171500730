import { useState } from 'react';

const Node = ({ node, srcNode, scales, featureMap, fallbackColor, connectNodes }) => {
    const [selected, setSelected] = useState(false);
 //   if( srcNode && (srcNode.id === node.uuid) ) {
  //       if (selected) {
 //            setSelected(true);
  //       }
 //        else {
 //            setSelected(false);
 //        }
 //   }

    var nodeClass = selected ? 'node-selected' : 'node';
    //console.log(nodeClass)
    let x = scales['x'](node['x']);
    let y = scales['y'](node['y']);
    if ((x < 0) || (y < 0)) {
            console.log(`xy out of bounds for ${node['family']} ${x},${y}`)
    }
    let color = featureMap[node['family']]
    if (!color) {
         console.log(`Missing feature color for ${node['family']}`);
         color = fallbackColor;
    }
     return (
         <svg>
             <circle className={nodeClass}
                 cx={x}
                 cy={y}
                 key={node['uuid']}
                 color={color}
                 fill={color}
                 onClick={connectNodes}
                 id={node['uuid']}
                 title={'node-' + node['uuid']}
             />
         </svg>
        )
};

export default Node;