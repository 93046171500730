import { v4 as uuidv4 } from 'uuid';
const RoomMatrix = ({ matrix, roomId, graphSetId, projectName }) => {
    // console.log(matrix);
    if (roomId === -1) { return (<div />) }
    const drawRow = (row) => {
        return (
            <div className="matrixEntry">
                {row.map((r) => {
                    if (r === 1) {
                        return (
                            <span className="matrix-on">{r}</span>
                        )
                    }
                    else {
                        return (
                            <span className="matrix-off">{r}</span>
                        )
                    }
                }
                )}
            </div>
        )
    }

    return (
        <div id="room-matrix">
            <a href={`/api/projects/${projectName}/graphsets/${graphSetId}/rooms/${roomId}/adjacency-matrix`}>

                <button>Download&nbsp;Matrix</button>

            </a>
            <div id="room-matrix-detail">

                {matrix.map(drawRow)}

            </div>
        </div>
    );
};
export default RoomMatrix;