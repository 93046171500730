import { useState, Button } from 'react';
import { Link } from "react-router-dom";
import ProjectPickerDetail from '../components/ProjectPickerDetail';

const ProjectPicker = ({ projects, loadFromClarity }) => {
    const maybeLink = (p) => {
        if (p.path) {
            return (
                <Link to="/app" state={{ projectName: p.name }}><button>Load&nbsp;into&nbsp;Editor</button></Link>
            )
        }
        else {
            return (<div />)
        }
    }

    return (

        projects.map((p) => (
            <ProjectPickerDetail
                project={p}
                key={p.name}
                loadFromClarity={loadFromClarity}
            />
        ))

    )
};

export default ProjectPicker;