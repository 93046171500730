const GraphsetPicker = ({ project, graphSets, maybeLink, maybeLinkMatrices }) => {
    if (graphSets.length === 0) {
        return (
            <div />
        )
    }

    return (
        <div>
            <h3>Existing Graphsets</h3>
            <div style={{ display: "table-row" }}>
                <div style={{ display: "table-cell" }}>Description</div>
                <div style={{ display: "table-cell" }}>ID</div>
                <div style={{ display: "table-cell" }}>No. rooms</div>
            </div>

            {
                graphSets.map((g) => (
                    <div key={g.uuid} style={{ display: "table-row" }}>
                        <div style={{ display: "table-cell" }}>{g.description}</div>
                        <div style={{ display: "table-cell" }}>{g.uuid}</div>
                        <div style={{ display: "table-cell" }}>{g.room_count}</div>
                        <div style={{ display: "table-cell" }}>{maybeLink(project, g)}</div>
                        <div style={{ display: "table-cell" }}>{maybeLinkMatrices(project, g)}</div>
                    </div>
                ))
            }
        </div>
    );
};

export default GraphsetPicker;