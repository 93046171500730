import { useState } from 'react';

const Edge = ({ edge, editEdges, manageEditEdges }) => {
    const [selected, setSelected] = useState(false);
    const editEdge = (e) => {
         if (selected) {
             setSelected(false);
         }
         else {
             setSelected(true);
         }
         manageEditEdges(e);
    }

    var edgeClass = selected ? 'feature-edge-selected' : 'feature-edge';
    return (
            <line className={edgeClass}
              x1={edge['x1']}
              x2={edge['x2']}
              y1={edge['y1']}
              y2={edge['y2']}
              onClick={editEdge}
              id={edge['uuid']}
              />
        )
};

export default Edge;