import { Routes, Route } from 'react-router-dom';
import App from './routes/App';
import DataLoader from './routes/DataLoader';


const GraphUIRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/app" element={<App />} />
                <Route path="/" element={<DataLoader />} />s
            </Routes>
        </>
    );
};

export default GraphUIRoutes;