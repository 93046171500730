import { useState, useEffect } from 'react';
import ProjectPicker from '../components/ProjectPicker';
import { Link } from "react-router-dom";

function DataLoader() {
    const [projects, setProjects] = useState([]);

    const loadFromClarity = (e) => {
        console.log(`Loading ${e.target.value}`)
        let project = e.target.value;
        fetch(`/api/projects/${project}/init`)
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                console.log("Init ok");
                window.location.reload();
            })
    }

    useEffect(() => {
        fetch('/api/possible-projects')
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                console.log(data)
                setProjects(data);
            });
    }, []);

    useEffect(() => {
        document.title = 'Room Graph Editor / Project Browser';
    }, []);

    return (
        <div className="project-wrapper">
            <div className="main-head" id="page-header">
              <h2>Room Graph Editor - Project Browser</h2>
            </div>
            <div id="project-picker">
              <ProjectPicker
                    projects={projects}
                    loadFromClarity={loadFromClarity}
              />
            </div>
        </div>
    )
}

export default DataLoader;