import { useState, useEffect, Button } from 'react';
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import GraphsetPicker from './GraphsetPicker';

const ProjectPickerDetail = ({ project, loadFromClarity }) => {
    const [graphSets, setGraphSets] = useState([]);
    const [formData, setFormData] = useState({ description: "" });
    const maybeLink = (p, g) => {
        if (project.path) {
            return (
                <Link to="/app" state={{ projectName: p.name, graphSet: g.uuid }}><button>Load&nbsp;into&nbsp;Editor</button></Link>
            )
        }
        else {
            return (<div />)
        }
    }

    const maybeLinkMatrices = (p, g) => {
        if (project.path) {
            return (
                <a href={`/api/projects/${p.name}/graphsets/${g.uuid}/matrices`}>Download Matrices</a>

            )
        }
        else {
            return (<div />)
        }
    }

    const handleCreate = (e) => {
        console.log('Creating');
        console.log(`Got ${formData.description}`);

        let graphsetId = uuidv4();

        let graphset = {
            "description": formData.description,
            "clarity_source": project.name,
            "uuid": graphsetId
        };

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(graphset)
        };

        fetch(`/api/projects/${project.name}/graphsets/${graphsetId}`, requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(`Created graphset ${graphsetId}`);
                console.log(data)
                var currentGraphSets = [...graphSets];
                currentGraphSets.push(graphset);
                setGraphSets(currentGraphSets);
            });
    };
    const handleChange = (e) => {
        console.log('Changing');
        const { name, value } = e.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    useEffect(() => {
        console.log(`Loading ${project.name}`);
        fetch(`/api/projects/${project.name}/graphsets`)
            .then((res) => {
                if (!res.ok) {
                    throw new Error("Not 2xxx response");
                }
                return res.json();
            })
            .then((data) => {
                console.log(data);
                setGraphSets(data.graphsets);
            })
            .catch((err) => {
                console.log(err)
            })
    }, []);

    let graphsetPicker;
    if (project.path) {
        graphsetPicker = (
            <div>
              <h3>Graphsets</h3>

            <p>Create a new set of graphs from the Clarity project source.
            </p>
            <div style={{ display: "table-row" }}>
                <div style={{ display: "table-cell" }}><label>Description:
                    <input type="text" name="description" onChange={handleChange} length="255" value={formData.description} />
                </label>
                </div>
                <div style={{ display: "table-cell" }}><button onClick={handleCreate} value="create">Create</button></div>
            </div>

            <GraphsetPicker project={project}
                graphSets={graphSets}
                maybeLink={maybeLink}
                maybeLinkMatrices={maybeLinkMatrices}
            />
            </div>
        );

    }
    else {
        graphsetPicker = (<div/>);
    }

    return (
        <div className="project-picker-detail">

            <div style={{ display: "table-row" }}>
                <div style={{ display: "table-cell" }}><h3>Project: {project.name}</h3></div>

                <div style={{ display: "table-cell" }}>
                    <button
                        value={project.name}
                        onClick={loadFromClarity}>
                        Initialize from Clarity
                    </button>

                </div>
            </div>
            {graphsetPicker}
          
        </div >
    );
};

export default ProjectPickerDetail;