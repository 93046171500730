const RoomLegend = ({ fallbackColor, roomId, features, featureMap }) => {
    var colorMap = {}
    if(roomId == -1) { return (<div/>)}
    features.map( (feature) => {
         if(feature.family in featureMap) {
             colorMap[feature.family] = featureMap[feature.family]
         }
         else {
             colorMap['Other'] = fallbackColor
         }
       });
    const drawLegendRow = (feature) => {
        return (
            <div className="legend-row" key={feature}>
                <div className="legend-value"
                 style={{color: colorMap[feature]}}
                >&#x2022;</div>
                <div className="legend-key">{feature}</div>
                </div>

        )
    }

    return (
            <div id="room-legend-nav">

                {Object.keys(colorMap).map(drawLegendRow)}

            </div>
    )
};

export default RoomLegend;