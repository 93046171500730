const RoomPicker = ({ roomIds, chosenRoom, onChange }) => {
    return (
        <div id="room-picker-local-nav">
            <h5>Load Graph from File</h5>
            <select name="room-picker-select-db" id="room-picker-db"
                onChange={onChange}
            >
                <option value="-1">--- Room Id ---</option>
                {roomIds.map((room) => (
                <option key={room} value={room} text={room}>{room}</option>
                ))}
            </select>
        </div>
    );
};

export default RoomPicker;