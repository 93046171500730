import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';


import {
   BrowserRouter,
} from "react-router-dom";

import GraphUIRoutes        from "./GraphUIRoutes";

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
    <BrowserRouter>
       <GraphUIRoutes />
    </BrowserRouter>
);

